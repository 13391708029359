import { Toast } from "antd-mobile";

export function getQueryObject(url: string) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf("?") + 1);
  const obj: any = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

export function copyToClipboard(str: string) {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}


const isProd = process.env.NODE_ENV === 'production'

export function dispatchSDK(func: ISdkTypes, ...args: any) {
  // 非正式服，不调用
  if (!isProd) return;
  if (!window.awsdk) {
    return Toast.show('Aiwan SDK未成功初始化，请联系管理人员')
  }
  switch (func) {
    case 'changeLogin':
      window.awsdk.changeLogin();
      break
    case 'changePassword':
      window.awsdk.changePassword();
      break
    case 'certification':
      window.awsdk.certification();
      break
    case 'share':
      window.awsdk.share(...args);
      break
    case 'bindPhone':
      window.awsdk.bindPhone();
      break
    case 'loginFailure':
      window.awsdk.loginFailure();
      break
    case 'openBrowser':
      window.awsdk.openBrowser(...args);
      break;
    case 'startApplication':
      window.awsdk.startApplication(...args);
      break;
    case 'reportEvent':
      window.awsdk.reportEvent(...args);
      break;
    case 'resetLogFlag':
      window.awsdk.resetLogFlag();
      break;
    case 'bindWX':
      window.awsdk.bindWX();
      break;
    case 'setAccountAlias':
      window.awsdk.setAccountAlias();
      break;
    default:
      Toast.show('调用的方法不存在')
  }
}