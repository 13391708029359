import * as React from 'react';
import { Toast } from 'antd-mobile';

const PageLoading = () => {
  React.useEffect(() => {
    Toast.loading('加载中', 0);
    return () => {
      Toast.hide();
    };
  }, []);
  return <></>;
};

export default PageLoading;