import { getQueryObject } from './utils/extend';

const init = () => {
  try {
    const params: any = getQueryObject(window.location.href) || {};
    localStorage.setItem('token', params.token);
  } catch(err) {
    console.log(err)
  }
}

init();