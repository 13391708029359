import './init'
import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import PageLoading from './components/PageLoading'
import './assets/styles/antd-reset.scss'

const Account = lazy(
  () => import(/* webpackChunkName: "Account" */ './pages/Account')
)
const Redpacket = lazy(
  () => import(/* webpackChunkName: "Redpacket" */ './pages/Redpacket')
)
const Share = lazy(
  () => import(/* webpackChunkName: "Share" */ './pages/Share')
)
const Gift = lazy(() => import(/* webpackChunkName: "Gift" */ './pages/Gift'))
const Agreement = lazy(
  () => import(/* webpackChunkName: "Agreement" */ './pages/Agreement')
)
const More = lazy(() => import(/* webpackChunkName: "More" */ './pages/More'))

const App = () => {
  return (
    <Router>
      <Suspense fallback={<PageLoading />}>
        <Switch>
          <Route exact path="/" component={() => <Redirect to="/account" />} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/redpacket" component={Redpacket} />
          <Route exact path="/share" component={Share} />
          <Route exact path="/gift" component={Gift} />
          <Route exact path="/agreement" component={Agreement} />
          <Route exact path="/more" component={More} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
